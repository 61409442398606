
import { send as logSend, triggerPV, logMixin } from 'smith-log';
export default {
  name: 'Homepage',
  components: {},
  mixin: [logMixin],
  data () {
    return {
      activeIndex: '1',
      isB: true,
      isC: false,
      switchBthText: "I'm a Job Seeker",
      isHome: true,
      isHomepage: true,
      isDownload: false,
      isRecruiter: true,
      isRecruiters: false,
      isJobSeeker: false,
      pageName: '/'
    }
  },
  computed: {
    isAboutProduct() {
      return this.$route.name === 'homepage'
    }
  },
  fetch () {
    // console.log('====>', window.location.href.indexOf('/recruiter'))
    if (this.$route.path.indexOf('/recruiter') > -1) {
      this.switchBthText = "I'm a Job Seeker"
      this.isRecruiter = true
      this.isRecruiters = true
      this.isHomepage = false
      this.isDownload = false
      // this.pageName = '/recruiter'
    } else if(this.$route.path.indexOf('/jobseeker') > -1) {
      this.switchBthText = "I'm a Recruiter"
      this.isRecruiter = false
      this.isJobSeeker = true
      this.isHomepage = false
      this.isDownload = false
      // this.pageName = '/jobseeker'
    }
    if (this.$route.path.indexOf('/download') > -1) {
      // this.pageBthDownload()
      this.isHome = false
      this.$router.push('/download')
      this.isHomepage = false
      this.isDownload = true
    }
    if (this.$route.path.indexOf('/homepage') > -1) {
      // this.pageBthDownload()
      this.isHome = true
      this.$router.push('/homepage')
      this.isHomepage = false
      this.isDownload = false
    }
    if (this.$route.path.indexOf('/job/') > -1) {
      this.isHome = true
      this.isHomepage = false
      this.isDownload = false
    }
    if (this.$route.path.indexOf('/apply/') > -1) {
      // this.pageBthDownload()
      this.isHome = true
      this.isHomepage = false
      this.isDownload = false
    }

  },
  mounted () {
    window.addEventListener('popstate', this.popstate, false)
  },
  destroyed () {
    window.removeEventListener('popstate', this.popstate, false)
  },
  methods: {
    switchBth () {
      if (window.location.href.indexOf('/recruiter') > -1) {
        this.switchBthText = "I'm a Recruiter"
        this.$router.push('/jobseeker')
        this.isRecruiter = false
        this.pageName = '/jobseeker'
      } else if(window.location.href.indexOf('/jobseeker') > -1) {
        this.switchBthText = "I'm a Job Seeker"
        this.$router.push('/recruiter')
        this.isRecruiter = true
        this.pageName = '/recruiter'
      } else {
        this.switchBthText = "I'm a Recruiter"
        this.$router.push('/jobseeker')
        this.isRecruiter = false
      }
    },
    onRecruiter() {
      if (this.isRecruiter) {
        console.log('recruiter')
        logSend('MC', 'e_re_website_download', {
          region_id: 1
        })
      } else {
        console.log('jobseeker')
        logSend('MC', 'e_ca_website_download', {
          region_id: 1
        })
      }
      this.pageBthDownload()
    },
    onRecruiterBth() {
      if (this.isRecruiter) {
        logSend('MC', 'e_re_website_jobseeker', {
          region_id: 1
        })
      } else {
        logSend('MC', 'e_ca_website_recruiter', {
          region_id: 1
        })
      }
      this.switchBth()
    },
    pageBthHomepage () {
      this.isHome = true

      this.isHomepage = true
      this.isDownload = false

      // if(this.isRecruiter) {
      //   this.$router.push('/recruiter')
      //   this.switchBthText = "I'm a Job Seeker"
      //   console.log(this.isRecruiter)
      // } else {
      //   this.$router.push('/jobseeker')
      //   this.switchBthText = "I'm a Recruiter"
      //   console.log(this.isRecruiter)
      // }
      // this.$router.push(window.sessionStorage.getItem('page'))
      this.$router.push('/')
    },
    logoBth() {
      this.$router.push('/')
    },
    pageBthDownload () {
      if (window.location.href.indexOf('/recruiter') > -1) {
        this.pageName = '/recruiter'
      } else if(window.location.href.indexOf('/jobseeker') > -1) {
        this.pageName = '/jobseeker'
      }
      window.sessionStorage.setItem('page',this.pageName)
      this.isHome = false
      this.$router.push('/download')
      this.isHomepage = false
      this.isDownload = true
    },
    popstate () {
      if (window.location.href.indexOf('/download') > -1) {
        this.isHome = true
        this.isHomepage = true
      } else {
        this.isHome = true
        this.isHomepage = true
      }
      if (window.location.href.indexOf('/recruiter') > -1) {
        this.switchBthText = "I'm a Job seeker"
      } else {
        this.switchBthText = "I'm a Recruiter"
      }
    },
    goJobSeekerBth() {
      this.isJobSeeker = true
      this.isRecruiters = false
      this.isDownload = false
      logSend('MC', 'e_re_website_jobseeker', {
          region_id: 1
        })
      this.$router.push('/jobseeker')
    },
    goRecruiterBth() {
      this.isRecruiters = true
      this.isJobSeeker = false
      this.isDownload = false
      logSend('MC', 'e_ca_website_recruiter', {
          region_id: 1
        })
      this.$router.push('/recruiter')
    },
    goWebapp() {
      window.open('https://www.hirey.com/web/for-you', '_self')
    },
    goAboutProduct() {
      this.isHomepage = false
      this.$router.push('/homepage')
    }
  }

}
