
export default {
  name: 'footer-us',
  components: {},
  data () {
    return {
      twitter: 'https://twitter.com/HireyApp',
      linkedin: 'https://www.linkedin.com/company/89732496/admin/',
      facebook: 'https://www.facebook.com/HireyApp-101849606067445',
      youtube: '',
      ins: 'https://www.instagram.com/hireyapp/',
      tiktok: 'https://www.tiktok.com/@hireyapp?_t=8WmyePq3q9a&_r=1',
      aboutUs: '',
      features: '',
      news: '',
      Newsroom: '',
      recruiters: '',
      download: '',
      scheduleCall: '',
      emailUrl: 'mailto:support@hirey.com',
      refundUrl: 'mailto:refund@hirey.com',
      privacyPolicy: 'https://terms.hirey.com/en/privacy_policy',
      termsConditions: 'https://terms.hirey.com/en/terms_conditions',
      termsServiceAgreement: 'https://terms.hirey.com/en/service_agreement/',
      loginIn: '',
      isTwitter: false,
      isLinkedin: false,
      isFaecbook: false,
      isIns: false,
      isTiktok: false,

      privacyPolicyUnderline: '',
      emailUnderline: '',
      refundUnderline: '',
      termsConditionsUnderline: '',
      termsServiceAgreementUnderline: '',
      forEmployersUnderline: '',
      forJobSeekersUnderline: '',
      blogUnderline: '',

      hover: false
    }
  },
  async fetch () {
  },
  created () {
  },
  mounted () {

  },
  methods: {
    linkClick (url) {
      window.open(url, '_blank')
    },
    hireyClick(page) {
      if (page === 'recruiter') {
        this.$router.push('/recruiter')
      } else if (page === 'jobseeker') {
        this.$router.push('/jobseeker')
      } else if (page === 'blog') {
        this.$router.push('/blog/list')
      }
    },
    logoBth() {
      this.$router.push('/')
    },
    twitterMouseover () {
      this.isTwitter = true
    },
    twitterMouseleave() {
      this.isTwitter = false
    },
    linkedinMouseover() {
      this.isLinkedin = true
    },
    linkedinMouseleave() {
      this.isLinkedin = false
    },
    facebookMouseover() {
      this.isFaecbook = true
    },
    facebookMouseleave() {
      this.isFaecbook = false
    },
    insMouseover() {
      this.isIns = true
    },
    insMouseleave() {
      this.isIns = false
    },
    tiktokMouseover() {
      this.isTiktok = true
    },
    tiktokMouseleave() {
      this.isTiktok = false
    },
    forEmployersMouseover() {
      this.forEmployersUnderline = 'text-decoration:underline'
    },
    forEmployersMouseleave() {
      this.forEmployersUnderline = ''
    },
    forJobSeekersMouseover() {
      this.forJobSeekersUnderline = 'text-decoration:underline'
    },
    forJobSeekersMouseleave() {
      this.forJobSeekersUnderline = ''
    },
    blogMouseover() {
      this.blogUnderline = 'text-decoration:underline'
    },
    blogMouseleave() {
      this.blogUnderline = ''
    },
    emailMouseover() {
      this.emailUnderline = 'text-decoration:underline'
    },
    emailMouseleave() {
      this.emailUnderline = ''
    },
    refundMouseover() {
      this.refundUnderline = 'text-decoration:underline'
    },
    refundMouseleave() {
      this.refundUnderline = ''
    },
    privacyPolicyMouseover() {
      this.privacyPolicyUnderline = 'text-decoration:underline'
    },
    privacyPolicyMouseleave() {
      this.privacyPolicyUnderline = ''
    },
    termsConditionsMouseover() {
      this.termsConditionsUnderline = 'text-decoration:underline'
    },
    termsConditionsMouseleave() {
      this.termsConditionsUnderline = ''
    },
    termsServiceAgreementMouseover() {
      this.termsServiceAgreementUnderline = 'text-decoration:underline'
    },
    termsServiceAgreementMouseleave() {
      this.termsServiceAgreementUnderline = ''
    }
  }
}
